import * as React from 'react';
import Logo from 'src/assets/react/logo-black.svg';
import { PageLayout } from 'src/components/Layout/PageLayout';
import { InputForm } from 'src/components/Workshop/InputForm';

const WorkshopPage: React.FC = () => {
  return (
    <PageLayout pageTitle='BETA Sign up'>
      <img src={Logo} alt='LinkScore Logo' style={{ width: '200px', display: 'block', margin: 'auto' }} />
      <h2 style={{ textAlign: 'center', fontWeight: 'normal', padding: '40px 0 30px' }}>
        Sign up to be notified when LinkScore BETA is ready to try out
      </h2>
      <InputForm />
    </PageLayout>
  );
};

export default WorkshopPage;
