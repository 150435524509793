import * as React from 'react';
import { combineClasses, isValidEmail } from 'src/utils';
import { Container } from '../Container/Container';
import { InputField } from '../InputField/InputField';
import { Button } from '../Button/Button';
import { INotificationSignUpValues, signUpService } from './Service';
import css from './InputForm.module.scss';

interface IMessage {
  text: string;
  isError: boolean;
}

interface IForm {
  isValid: boolean;
  loading: boolean;
  message: IMessage;
  values: INotificationSignUpValues;
}

export const InputForm: React.FC = () => {
  const [form, setForm] = React.useState<IForm>({
    isValid: false,
    loading: false,
    message: {
      text: '',
      isError: false,
    },
    values: {
      name: '',
      jobTitle: '',
      company: '',
      email: '',
    },
  });
  function updateValue(fieldName: string, value: string) {
    const values = { ...form.values, [fieldName]: value };
    const validEmail = isValidEmail(values.email);
    const formValues: string[] = Object.values(values);
    const isValid = (formValues.filter(v => v.trim().length).length === formValues.length && validEmail) || false;

    setForm({ ...form, isValid, values });
  }
  function onSubmit() {
    setForm({ ...form, loading: true });
    signUpService(form.values)
      .then(response => {
        if (response.error) {
          throw Error(response.error.message);
        }

        setForm({
          ...form,
          loading: false,
          message: { ...form.message, text: 'You have been successfully signed up!' },
        });
      })
      .catch((error: Error) => {
        setForm({
          ...form,
          loading: false,
          message: { text: `ERROR: ${error.message}`, isError: true },
        });
      });
  }

  const messageClass = combineClasses([css.message, form.message.isError && css.error]);

  return (
    <section className={css.section}>
      <Container>
        <div className={css.formCard}>
          <form className={css.form}>
            <InputField label='Name' name='name' type='text' required value={form.values.name} onChange={updateValue} />
            <InputField
              label='Job title'
              name='jobTitle'
              type='text'
              required
              value={form.values.jobTitle}
              onChange={updateValue}
            />
            <InputField
              label='Company'
              name='company'
              type='text'
              required
              value={form.values.company}
              onChange={updateValue}
            />
            <InputField
              label='Email address'
              name='email'
              type='email'
              required
              value={form.values.email}
              onChange={updateValue}
            />
            <Button className={css.signButton} disabled={!form.isValid} loading={form.loading} onClick={onSubmit}>
              Sign up
            </Button>
            <div className={messageClass}>{form.message.text}</div>
          </form>
        </div>
      </Container>
    </section>
  );
};
