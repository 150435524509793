import * as React from 'react';
import css from './InputField.module.scss';

type HTMLInputType = 'text' | 'number' | 'password' | 'email';

interface IInputFieldProps {
  description?: string;
  label: string;
  name: string;
  required: boolean;
  type: HTMLInputType;
  value: string;
  onChange(name: string, value: string): void;
}

export const InputField: React.FC<IInputFieldProps> = ({
  label,
  description,
  type,
  name,
  onChange,
  value,
  required,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [focused, setFocused] = React.useState(false);
  const labelClassName = value.length || focused ? css.elevatedLabel : '';

  const onValueChanged = () => {
    const inputValue = inputRef && inputRef.current ? inputRef.current.value : '';
    onChange(name, inputValue);
  };

  const onInputFocus = () => {
    setFocused(true);
  };

  const onInputBlur = () => {
    setFocused(false);
  };

  return (
    <div className={css.formInput}>
      <label htmlFor={name} className={labelClassName}>
        {label}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        ref={inputRef}
        onChange={onValueChanged}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        required={required}
      />
      {description}
    </div>
  );
};
