/* eslint-disable react/button-has-type */
import * as React from 'react';
import { combineClasses } from 'src/utils';
import { Spinner } from '../Spinner/Spinner';
import css from './Button.module.scss';

interface IButtonProps {
  /**
   * One or multiple CSS classes to overwrite or complement the default CSS of the button.
   */
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick(): void;
}

export const Button: React.FC<IButtonProps> = ({ children, loading, disabled, className, onClick }) => {
  const buttonClassName = combineClasses([className, css.button, loading && css.loading, disabled && css.disabled]);
  let title = '';

  if (disabled) {
    title = 'Button disabled until all required input fields contain valid data';
  } else if (loading) {
    title = 'Please wait...';
  }

  return (
    <button
      type='button'
      className={buttonClassName}
      onClick={loading || disabled ? undefined : onClick}
      title={title}
      disabled={disabled}
    >
      {loading ? <Spinner /> : children}
    </button>
  );
};
