import * as React from 'react';
import { combineClasses } from 'src/utils';
import css from './Spinner.module.scss';

interface ISpinnerProps {
  /**
   * Use this to specify one or multiple CSS classes to apply custom styles on top of the default ones.
   */
  className?: string;
}

export const Spinner: React.FC<ISpinnerProps> = ({ className }) => {
  const spinnerClassName = combineClasses([css.spinner, className]);

  return (
    <div className={spinnerClassName}>
      <div className={css.spinnerCircle} />
    </div>
  );
};
