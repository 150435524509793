// const API_ENDPOINT = 'https://app.linkscore.co.uk/_api/notif-sign-up';
const API_ENDPOINT = 'https://beta-api.linkscore.co.uk/_api/notif-sign-up';

export interface INotificationSignUpValues {
  name: string;
  jobTitle: string;
  company: string;
  email: string;
}

interface IServiceResponse {
  error?: {
    message: string;
  };
}

export async function signUpService(inputValues: INotificationSignUpValues): Promise<IServiceResponse> {
  try {
    const response = await fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(inputValues),
    });
    const contentType = response.headers.get('content-type');

    if (!(contentType && contentType.indexOf('application/json') > -1)) {
      throw Error(await response.text());
    }

    const data = (await response.json()) as IServiceResponse;
    if (data.error) {
      throw Error(data.error.message);
    }

    return data;
  } catch (error) {
    return {
      error,
    };
  }
}
